<script setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import PageMessage from "@/Components/PageMessage.vue";

defineOptions({
    layout: GuestLayout,
});

const props = defineProps({
    flash: {
        type: Object,
    },
    email: {
        type: String,
    },
});

const form = useForm({
    email: props.email,
});
const submit = () => {
    form.post(route('check.store'));
};
</script>

<template>
    <Head :title="__('check.title')" />

    <form @submit.prevent="submit">
        <div>
            <h1 class="text-center mb-2 font-bold text-xl" v-html="__('check.header')"></h1>
            <p class="text-center" v-html="__('check.subheader')"></p>
        </div>

        <PageMessage v-if="flash.status" class="mt-2" prefix="check.messages" type="success" :message="flash.status" />
        <PageMessage v-if="flash.error" class="mt-2" prefix="check.errors" type="error" :message="flash.error" />

        <div class="my-4">
            <InputLabel for="email" :value="__('check.form.email')" />

            <TextInput
                id="email"
                type="email"
                class="mt-1 block w-full"
                v-model="form.email"
                required
                autofocus
                autocomplete="username"
            />

            <InputError class="mt-2" prefix="check.form.errors" :message="form.errors.email" />
        </div>

        <div class="flex flex-col mt-6">
            <PrimaryButton
                :class="{ 'opacity-25': form.processing }"
                :disabled="form.processing"
                v-html="__('check.form.submit')"
            />
        </div>
    </form>
</template>
